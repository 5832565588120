import environment from '../environment';

let API_URL = '';
let API_URL_SOCKET = '';

API_URL = environment.baseURL;
API_URL_SOCKET = environment.baseURLSocket;

export default {
  API_URL,
  API_URL_SOCKET,
};
