const initialState = {
  notification: {},
};

export const NotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATION':
      return { ...state, notification: action.notification };
    case 'GET_NOTIFICATION':
      return { ...state };
    default:
      return { ...state };
  }
};
