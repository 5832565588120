const initialState = {
  active: false,
};

export const LogoffReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ENABLE_ACTIVE':
      localStorage.setItem('logoff', true);
      return {
        ...state,
        active: true,
      };
    case 'DISABLED_ACTIVE':
      localStorage.removeItem('logoff');
      return {
        ...state,
        active: false,
      };
    default:
      if (localStorage.getItem('logoff')) {
        return {
          ...state,
          active: true,
        };
      }
      return state;
  }
};
