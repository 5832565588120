const initialState = {
    message: {},
};
  
  export const ChatReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_MESSAGE_CHAT':
        return { ...state, message: action.message };
      case 'GET_MESSAGE_CHAT':
        return { ...state };
      default:
        return { ...state };
    }
  };
  