import { all, takeLatest } from 'redux-saga/effects';
import { setCliente } from '~/components/Services/Auth';

export function loadEntidade({ payload }) {
  if (!payload) return;
  if (!payload.main) return;

  const { entidade } = payload.main;
  if (entidade) {
    setCliente(entidade.codigo);
  }
}

export default all([
  // utilizando a action do redux-persist para pegar o token salvo
  takeLatest('persist/REHYDRATE', loadEntidade),
]);
