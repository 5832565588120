/* eslint-disable import/no-cycle */
import axios from 'axios';
import qs from 'qs';
import PubSub from 'pubsub-js';
import { toastr } from 'react-redux-toastr';
import Message from '~/components/helpers/MessageSweet';
import consts from '~/config';
import {
  logout,
  getToken,
  getModulo,
  getEntidade,
} from '~/components/Services/Auth';

// eslint-disable-next-line no-unused-vars
const message = new Message();

export const api = axios.create({
  headers: { Authorization: `${getToken()}` },
  onUploadProgress(progressEvent) {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    PubSub.publish('progressUpload', percentCompleted);
  },
  onDownloadProgress(progressEvent) {},
  baseURL: consts.API_URL,
});

async function sessionExpiration() {
  message.confirmationErrorDialog('Sessão Expirada!');
  logout();
}

async function sessionExpirationIpBloqueado() {
  logout();
}
// Interceptors
api.interceptors.response.use(
  response => {
    PubSub.publish('loading', false);
    if (response.data && response.data.message) {
      toastr.success('Sucesso', response.data.message);
    }
    PubSub.publish('loading', false);
    return response;
  },
  e => {
    PubSub.publish('loading', false);
    if (e) {
      if (
        e.response.status !== undefined &&
        e.response.status !== 'undefined' &&
        e.response.status === 305
      ) {
        localStorage.removeItem('logoff');
        logout();
      }
    }

    if (e.response.status === 503) {
      sessionExpiration();
      PubSub.publish('loading', false);
    } else if (e.response.status === 504) {
      message.confirmationErrorDialogCallback(e.response.data.message, () => {
        logout();
      });
      
      PubSub.publish('loading', false);
    } else {
      if (e.response) {
        // Message Delete List
        if (e.response.data.list_delete) {
          const title = e.response.data.title_modal
            ? e.response.data.title_modal
            : 'Status de Registros Excluídos';

          message.messageDeleteListInfor(title, e.response.data.message);
          return null;
        }
        // Message Validation
        if (e.response.data.validation) {
          // eslint-disable-next-line array-callback-return
          Object.keys(e.response.data.validation).filter(key => {
            message.error(e.response.data.validation[key][0]);
          });
        } else {
          message.error(e.response.data.message);
        }
      }

      return Promise.reject(e);
    }
    return null;
  }
);

export async function getData(path, action, query, modulo) {
  PubSub.publish('loading', true);
  const entidade = getEntidade();
  const response = await api.get(
    `${entidade}/${modulo || getModulo() || 'ponto'}/${path}/${action}`,
    qs.stringify(query)
  );
  return response.data;
}

export async function getDataParams(
  path,
  action,
  data,
  modulo,
  loading = true
) {
  const entidade = getEntidade();

  if (getModulo() === 'portal' && loading) PubSub.publish('loading', true);

  const response = await api.get(
    `${entidade}/${modulo || getModulo() || 'ponto'}/${path}/${action}`,
    data
  );
  return response.data;
}

export async function getSubmit(path, method, action, data, modulo, entidade, loading = false) {
  const entidadeUrl = entidade || getEntidade();
  if (getModulo() === 'portal' && loading) { 
    PubSub.publish('loading', true); 
  }

  const response = await api[method](
    `${entidadeUrl}/${modulo || getModulo() || 'ponto'}/${path}/${action}`,
    data
  );
  return response.data;
}

export async function submit(
  path,
  method,
  action,
  data,
  modulo,
  loading = true
) {
  const entidade = getEntidade();
  if (getModulo() === 'portal' && loading) PubSub.publish('loading', true);

  return api[method](
    `${entidade}/${modulo || getModulo() || 'ponto'}/${path}/${action}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ).then(() => {});
}

export async function create(endPoint, data, modulo) {
  const entidade = getEntidade();
  return api
    .post(`${entidade}/${modulo || getModulo() || 'ponto'}/${endPoint}`, data)
    .then(() => {});
}

let percentCompleted = 0;
export async function uploadFileProgressBar(
  path,
  action,
  data,
  modulo,
  registros
) {
  const apiProgress = axios.create({
    headers: { Authorization: `${getToken()}` },
    baseURL: consts.API_URL,
    onUploadProgress(progressEvent) {
      percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      PubSub.publish('progressUpload', percentCompleted);
    },
    onDownloadProgress(progressEvent) {
      const line = progressEvent.target.responseText.split('|');
      // console.log(progressEvent);
      let row = line[line.length - 1];
      row = JSON.parse(row);
      percentCompleted = Math.round((row.registros / row.total) * 100);

      if (registros) {
        PubSub.publish('progressUpload', {
          titulo: row.titulo ? row.titulo : '',
          registros: row.registros,
          total: row.total,
          progress: percentCompleted,
        });
      } else {
        PubSub.publish('progressUpload', percentCompleted);
      }
    },
  });

  // Interceptors
  apiProgress.interceptors.response.use(
    response => {
      const row = response.data.split('---');
      
      if (response.data.message) {
        toastr.success('Sucesso', response.data.message);
      }

      if(row[1]) {
        const res = JSON.parse(row[1]);
        if (res.list_delete) {
          const title = res.title_modal
            ? res.title_modal
            : 'Status de Registros Excluídos';
  
          message.messageDeleteListInfor(title, res.message, res.width);
          return response;
        }
      }
      
      PubSub.publish('loading', false);
      return response;
    },
    e => {
      PubSub.publish('loading', false);

      if (e.response.status === 305) {
        localStorage.removeItem('logoff');
        logout();
      }

      if (e.response.status === 503) {
        sessionExpiration();
      } else {
        PubSub.publish('loading', false);
        if (e.response) {
          // Message Delete List
          if (e.response.data.list_delete) {
            const title = e.response.data.title_modal
              ? e.response.data.title_modal
              : 'Status de Registros Excluídos';

            message.messageDeleteListInfor(title, e.response.data.message);
            return null;
          }
          // Message Validation
          if (e.response.data.validation) {
            // eslint-disable-next-line array-callback-return
            Object.keys(e.response.data.validation).filter(key => {
              message.error(e.response.data.validation[key][0]);
            });
          } else {
            message.error(e.response.data.message);
          }
        }
        PubSub.unsubscribe('loading');
        return Promise.reject(e);
      }
      return null;
    }
  );
  const entidade = getEntidade();
  const response = await apiProgress.post(
    `${entidade}/${modulo || getModulo() || 'ponto'}/${path}/${action}`,
    data
  );
  if(response) {
    if(response.data) {
      return response.data;
    }
  }
  
  return ;
  
}

export default api;
