/* eslint-disable import/no-cycle */
import { store } from '~/store';

// Auth
export const TOKEN_KEY = '@itargetiponto-Token';
// Permissoes
export const TOKEN_KEY_PERMISSION = '@itargetiponto-Token-permission';
// Cliente
export const CLIENTE_KEY = '@itargetiponto-cliente';

export const isAuthenticated = () => {
  return localStorage.getItem(TOKEN_KEY) !== null;
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const setToken = token => localStorage.setItem(TOKEN_KEY, token);

export const getModulo = () => {
  return store.getState().main.modulo;
};

export const getEntidade = () => {
  return store.getState().main.entidade.codigo;
};


export const getTokenPermission = () =>
  localStorage.getItem(TOKEN_KEY_PERMISSION);

export const redirect = path => {
  let url = `http://${window.location.host}`;
  if (path) {
    url += path;
  }
  if (url !== window.location.href) window.location.href = url;
};

export const loginStorage = (token, permission, path) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(TOKEN_KEY_PERMISSION, permission);
  redirect(path);
};

export const selectModulo = path => {
  redirect(path);
};

export function logout() {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_KEY_PERMISSION);
  let data = JSON.parse(localStorage.getItem('persist:@rh247'));
  let dataM = JSON.parse(data.main);
  dataM.modulo = '';
  data.main = dataM;
  localStorage.setItem('persist:@rh247', data);
  // eslint-disable-next-line no-use-before-define
  redirect(`/${getCliente()}/login`);
};

export const isClient = () => {
  return localStorage.getItem(CLIENTE_KEY) !== null;
};

export const removeCliente = () => {
  localStorage.removeItem(CLIENTE_KEY);
  localStorage.removeItem('persist:@rh247');
  redirect();
};

export const setCliente = codigo => {
  localStorage.removeItem(CLIENTE_KEY);
  localStorage.setItem(CLIENTE_KEY, codigo);
};

export const getCliente = () => localStorage.getItem(CLIENTE_KEY);
