const dev = {
  baseURL: process.env.REACT_APP_API_URL,
  baseURLSocket: process.env.REACT_APP_API_URL_SOCKET,
  baseURLDynanSoft: process.env.REACT_APP_API_URL_DYNANSOFT,
};

const prod = {
  baseURL: 'https://api.rh247.com.br',
  baseURLSocket: 'https://socket.rh247.com.br',
  baseURLDynanSoft: 'https://api.rh247.com.br',
};


const config = process.env.NODE_ENV === 'production' ? prod : dev;



export default {
  ...config,
};
