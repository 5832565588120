const initialState = {
  saveMessages: false,
  atendente_id: null,
};
export const ChatChamadoReducer = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case 'SET_CHAT_CHAMADO':
      return {
        ...state,
        saveMessages: action.saveMessages,
        atendente_id: action.atendente_id,
      };
    default:
      return { ...state };
  }
};
