const initialState = {
  avatar: '',
  avatar_origin: '',
};

export const AvatarReducer = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case 'SET_AVATAR':
      return {
        ...state,
        avatar: action.avatar,
        avatar_origin: action.avatar_origin,
      };
    case 'GET_AVATAR':
      return { ...state };
    default:
      return { ...state };
  }
};
