/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import Reactotron, { trackGlobalErrors } from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';
import sagaPlugin from 'reactotron-redux-saga';

if (process.env.NODE_ENV === 'development') {
  const tron = Reactotron.configure()
    .use(reactotronRedux())
    .use(sagaPlugin())
    .use(trackGlobalErrors())
    .connect();

  tron.clear();

  console.tron = tron;
} else {
  const tron = { log: console.log };
  console.tron = tron;
}
