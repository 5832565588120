import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { LogoffReducer } from './all/LogoffReducer';
import { AvatarReducer } from './all/AvatarReducer';
import { ChatReducer } from './all/ChatReducer';
import { NotificationsReducer } from './all/NotificationsReducer';
import { ClerkStatusReducer } from './all/ClerkStatusReducer';
import { ChatChamadoReducer } from './all/ChatChamadoReducer';
import mainReducer from './main/reducer';

const rootReducer = combineReducers({
  form: formReducer,
  toastr: toastrReducer,
  LogoffReducer,
  AvatarReducer,
  ChatReducer,
  ClerkStatusReducer,
  NotificationsReducer,
  ChatChamadoReducer,
  main: mainReducer,
});

export default rootReducer;
