const initialState = {
  clerk: null,
};

export const ClerkStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CLERK_STATUS':
      return { ...state, clerk: action.clerk };
    default:
      return state;
  }
};
