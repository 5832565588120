import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './config/ReactotronConfig';
import { store, persistor } from './store';
// import ContextsProvider from '~/shared/contexts';
// import MainRouter from './routes/MainRouter';
import { GlobalStyle } from '~/styles/index';
import './index.css';

const ContextsProvider = React.lazy(() => import('~/shared/contexts'));
const MainRouter = React.lazy(() => import('./routes/MainRouter'));

function App() {  
  return (
    <Suspense fallback={() => ('Loading')}>
    <GlobalStyle id="app-rh247">
      <Provider store={store}>
        <ContextsProvider>
          <PersistGate persistor={persistor}>
            <MainRouter />
          </PersistGate>
        </ContextsProvider>
      </Provider>
    </GlobalStyle>
    </Suspense>
  );
}

export default App;
