import Swal from 'sweetalert2';

class Message {
  // eslint-disable-next-line class-methods-use-this
  async confirmationDelete(callback, msg = 'Deseja excluir este registro?') {
    Swal.fire({
      title: 'Exclusão',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim!',
    }).then(result => {
      if (result.value) {
        return callback();
      }
      return null;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async confirmation(msg, callback) {
    Swal.fire({
      title: 'Aviso',
      text: msg,
      icon: 'info',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
    }).then(result => {
      if (result.value) {
        return callback();
      }
      return false;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async confirmationProcess(msg = '') {
    let res = false;
    await Swal.fire({
      title: 'Confirmação',
      html: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then(result => {
      if (result.value) {
        res = true;
      }
    });
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async confirmationDeleteReturn(msg = 'Deseja excluir este registro?') {
    let res = false;
    await Swal.fire({
      title: 'Exclusão',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
    }).then(result => {
      if (result.value) {
        res = true;
      }
    });
    return res;
  }

  messageDelete() {
    this.messageSucess('Registro Removido com Sucesso!');
  }

  // eslint-disable-next-line class-methods-use-this
  messageSucess(msg, timer, title = 'Successo') {
    Swal.fire({
      icon: 'success',
      title: title,
      text: msg,
      timer
    });
  }

  success(msg) {
    Swal.fire({
      icon: 'success',
      title: 'Sucesso',
      text: msg,
    });
  }

  info(msg) {
    Swal.fire({
      icon: 'info',
      title: 'Oops',
      text: msg,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  errorDialog(e) {
    Swal.fire({
      icon: 'error',
      title: 'Oops!',
      text: e,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  error(e) {
    Swal.fire({
      icon: 'error',
      title: 'Atenção',
      html: e,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async confirmationErrorDialog(msg = null) {
    let res = false;
    Swal.fire({
      title: 'Sessão',
      text: msg,
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
    }).then(result => {
      if (result.value) {
        res = true;
      }
      return res;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  inforDialog(e = '') {
    Swal.fire({
      icon: 'info',
      title: 'Atenção!',
      // text: e,
      html: e,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  messageDeleteListInfor(title, msg, width = '50%') {
    Swal.fire({
      title: `<h5>${title}</h5>`,
      html: msg,
      width: width
    });
  }

  // eslint-disable-next-line class-methods-use-this
  showLoading() {
    Swal.showLoading();
  }

  async confirmationErrorDialog(msg = null) {
    let res = false;
    Swal.fire({
      title: 'Sessão',
      text: msg,
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
    }).then(result => {
      if (result.value) {
        res = true;
      }
      return res;
    });
  }

  async confirmationErrorDialogCallback(msg = null, callback) {
    let res = false;
    await Swal.fire({
      title: 'Sessão',
      text: msg,
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
    }).then(result => {
      if (result.value) {
        callback();
      }
      callback();
    });
  }
}
export default Message;
