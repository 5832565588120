import styled from "styled-components";

export const GlobalStyle = styled.div`
  @import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

  .main-header .navbar-header[data-background-color="blue"] {
    background-color: #08acce !important;
  }
  span.required {
    color: red !important;
  }
  body {
    margin: 0;
    font-family: "Poppins", Helvetica, sans-serif;
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1, 5;
    overflow-x: hidden;
  }
  .text-body-gray {
    color: #858585;
  }
  .mix-blend-mode {
    mix-blend-mode: multiply;
  }
  .round-50 {
    border-radius: 50%;
  }
  .avatar-border {
    padding: 5px;
    border: 3px solid #fd9f15;
  }
  .truncated-text {
    display: block;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #999;
  }
  a {
    cursor: pointer;
  }
  .nav li a {
    font-family: "Poppins", Helvetica, sans-serif;
    font-weight: 500 !important;
    color: rgb(160, 160, 160) !important;
  }
  .logo-img {
    width: 54px !important;
    height: 50px !important;
  }
  input,
  textarea,
  select {
    text-transform: uppercase;
  }
  table,
  table tr,
  table tr td,
  table tr td a {
    font-family: Helvetica, sans-serif !important;
    color: #666 !important;
  }
  .table-responsive {
    overflow: hidden;
  }
  .table th,
  .table tbody tr,
  .table tbody td {
    padding: 2px 5px !important;
  }
  table thead th {
    color: #858585 !important;
    font-weight: 400 !important;
  }
  .red {
    color: red;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  label.required,
  span.required {
    color: red !important;
  }
  .crud-btns {
    float: right;
  }
  .crud-btns button {
    margin: 2px;
  }

  #panels-tabs-btns {
    position: relative;
    z-index: 100;
    height: 15px;
    top: -20px;
  }
  #pills-tab {
    z-index: 100;
    position: fixed;
    background: white;
    /*top: -23px;*/
  }

  #pills-tab li {
    height: 39px;
    -webkit-box-shadow: 0px 7px 5px -2px rgba(77, 125, 133, 0.2);
    -moz-box-shadow: 0px 7px 5px -2px rgba(77, 125, 133, 0.2);
    box-shadow: 0px 7px 5px -2px rgba(77, 125, 133, 0.2);
  }
  #pills-home-tab {
    position: relative;
  }
  .panels-btn-close {
    font-size: 10px;
    font-weight: bold;
    color: rgb(138, 138, 138);
    position: absolute;
    z-index: 1000;
    top: 2px;
    width: 15px;
    text-align: center;
    right: 2px;
    border-radius: 3px;
    background: rgb(230, 228, 228);
    opacity: 0.3;
  }
  a.panels-btn-close {
    text-decoration: none;
  }
  .panels-btn-close:hover {
    background: #f3545d;
    color: white;
    opacity: 0.9;
  }
  .nav-pills.nav-secondary .nav-link.active {
    background: #08acce;
    border: 0px;
  }

  .show {
    display: block;
  }
  .hide {
    display: none;
  }
  .fieldset {
    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 2px;
    border-style: solid;
    margin-top: -20px;
    border-color: #eee;
    border-image: initial;

    legend {
      font-size: 15px;
      width: auto !important;
      padding: 0 5px;
    }
  }
  #btns-broadcast {
    width: 150px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  #btns-broadcast button {
    float: right;
  }
  .page-header {
    margin-bottom: 0px !important;
  }
  .p-autocomplete-items {
    margin: 0;
    padding: 0;
  }
  .p-autocomplete-items li {
    list-style: none;
    display: block;
  }
  .crud-btns .btn:focus {
    box-shadow: 2px 2px 10px rgb(44, 44, 44) !important;
  }
  .btn:focus {
    box-shadow: 2px 2px 10px rgb(44, 44, 44) !important;
  }

  .btn-grid-edit,
  .btn-grid-delete {
    padding: 0px;
    border-radius: 50%;
  }
  .btn-grid-edit i,
  .btn-grid-delete i {
    color: #fff;
    font-size: 17px;
    padding: 5px;
  }
  .container-login {
    padding: 60px 25px 50px !important;
  }
  .messages-wrapper {
    left: 0px !important;
    width: 100%;
  }
  .messages-wrapper .messages-body {
    height: calc(100vh - 420px) !important;
  }
  .contact-list {
    height: 400px;
    overflow: auto;
  }
  .float-left {
    float: left;
    text-align: center;
    width: 40px;
  }
  .input-lg input {
  }
  th.min-width,
  td.min-width {
    width: 5%;
  }
  .min-width input {
    width: 50px;
  }
  th.min-width-10 {
    width: 10%;
  }
  th.min-width-20,
  td.min-width-20 {
    width: 70px;
  }
  th.min-width-30,
  td.min-width-30 {
    width: 80px;
  }
  th.min-width-100,
  td.min-width-100 {
    width: 100px;
  }
  th.min-width-120,
  td.min-width-120 {
    width: 120px;
  }
  tr.selected {
    background-color: #c0ddfc !important;
  }
  tr.selected:hover,
  tr.selected:active {
    background-color: #c0ddfc !important;
  }

  /*FORM*/
  label {
    color: rgb(111, 117, 148) !important;
    font-family: "Poppins", Helvetica, sans-serif;
    font-size: 13px !important;
    font-weight: 400;
    margin-bottom: -5rem;
  }
  input.form-control,
  select.form-control {
    padding: 0.4rem 0.8rem !important;
  }
  .form-control input,
  .form-control select {
    height: 33px !important;
  }
  .page-title {
    font-family: "Poppins", Helvetica, sans-serif;
    color: rgb(90, 90, 90);
    font-size: 1.2em;
  }
  .crud-content {
    margin-top: 8px;
  }
  .brn {
    line-height: 1 !important;
    height: 38px !important;
    font-family: "Poppins", Helvetica, sans-serif;
    font-size: 1.2em;
  }
  .nav-search .input-group {
    border-radius: 0px;
    height: 35px;
  }
  .modal-title {
    font-size: 1.3em;
  }
  .DayPicker-Day--outside {
    color: #fff !important;
  }
  .DayPicker {
    font-size: 0.7rem !important;
  }
  .btn-custom {
    margin-top: 40px;
    height: 40px !important;
  }
  .btn-blue {
    background: #27c7e1;
    color: #fff;
    font-weight: bold;
  }
  .btn-custom-form {
    margin-top: 28px;
    height: 35px !important;
  }
  .font-sm {
    font-size: 0.9em;
  }
  #cx-infor-calendar {
    height: 100px;
  }
  a.link-desc {
    color: #000;
  }

  span.form-check-sign:focus,
  span.form-check-sign:visited,
  span.form-check-sign:hover {
    border: 1px solid blue !important;
  }

  input[type="checkbox"]:focus + span:before {
    outline: rgb(59, 153, 252) auto 5px !important;
  }

  input[type="radio"]:focus + label:before,
  input[type="text"]:focus {
    outline: rgb(59, 153, 252) auto 1px !important;
    border-radius: 1px solid rgb(59, 153, 252) !important;
  }
  input[type="radio"]:focus {
    outline: rgb(59, 153, 252) auto 1px !important;
    border-radius: 1px solid rgb(59, 153, 252) !important;
  }
  .react-select__control:hover,
  .react-select__control:focus {
    box-shadow: 0px !important;
  }
  .react-select__control input[type="text"]:focus {
    outline: rgb(59, 153, 252) auto 0px !important;
    border-radius: 0px solid rgb(59, 153, 252) !important;
  }

  @keyframes loading {
    from {
      display: none;
      background: rgba(0, 0, 0, 0);
    }
    to {
      display: block;
      background: rgba(0, 0, 0, 0.6);
    }
  }

  #loading {
    width: 100%;
    height: 100vh;
    color: #fff;
    position: fixed;
    z-index: 2000;
    background: rgba(0, 0, 0, 0.6);
  }

  #loading .content {
    margin: 20% auto;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    font-family: "Poppins", Helvetica, sans-serif;
  }
  /* TABLE */
  table tr td {
    min-height: 30px;
  }
  td.right {
    text-align: right;
  }
  td.left {
    text-align: left;
  }
  td.center {
    text-align: center;
  }
  .float-right {
    float: right;
  }
  .float-left {
    float: left;
  }
  /* FORM */
  .title-form-h6 {
    line-height: 0px;
    margin-left: -5px;
  }
  input.input-search {
    padding: 4.5px !important;
  }
  div.p-monthpicker {
    background: white;
    width: 100%;
  }
  .p-component {
    height: 31px !important;
  }
  div.p-monthpicker {
    background: white;
    width: 100%;
  }
  .p-dropdown,
  .p-component,
  .p-dropdown-clearable {
    width: 100%;
    height: 35px;
    border: 1px solid rgb(233, 230, 230) !important;
  }
  .p-dropdown-filter-container {
    width: 100%;
  }
  input.p-dropdown-filter {
    width: 100%;
  }
  /* MODAL */
  .modal-60w {
    width: 60%;
    max-width: none !important;
  }
  .modal-70w {
    width: 77%;
    max-width: none !important;
  }
  .modal-80w {
    width: 80%;
    max-width: none !important;
  }
  tr:focus {
    background: #bddaf8 !important;
  }
  #header-select-autocomplete .col-2,
  #header-select-autocomplete .col-3 {
    border-right: 1px solid #ccc;
    flex: 0 0 25%;
    max-width: 20%;
  }

  #content-select-autocomplete .col-1,
  #content-select-autocomplete .col-2,
  #content-select-autocomplete .col-3,
  #content-select-autocomplete .col-4 {
    border-right: 1px solid #ccc;
  }

  #content-select-autocomplete .col-1 {
    border-right: 1px solid #ccc;
  }
  #content-select-autocomplete .col-2,
  #content-select-autocomplete .col-3 {
    border-right: 1px solid #ccc;
    flex: 0 0 25%;
    max-width: 20%;
  }
  #header-select-autocomplete .col,
  #content-select-autocomplete .col {
    border-right: 1px solid #ccc;
  }
  .custom-option div.row:hover {
    background: #71b2f8;
  }
  .react-select__menu {
    overflow-x: hidden;
  }

  /*ALERTS*/
  .alert-info {
    padding: 8px;
    color: #0c5460;
    background-color: #97c6f8;
  }

  #bar-infor-funcionarios {
    /* -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease; */
  }
  .infor-relative {
    position: relative;
    top: auto;
    width: 100%;
    opacity: 9;
  }
  @keyframes transparencia {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.9;
    }
  }
  .infor-fixed {
    transition: position 250ms, top 250ms, width 250ms;
    /* position: 'fixed';
  top: '16%';
  z-index: '9999';
  width: '75%'; */
    animation: transparencia 500ms;
    position: fixed;
    top: 15%;
    z-index: 9999;
    right: 15px;
    width: 80%;
  }

  .check i {
    font-size: 20px;
    font-weight: bold;
    color: rgb(18, 194, 18);
  }
  .incheck i {
    font-size: 20px;
    font-weight: bold;
    color: rgb(117, 117, 117);
  }

  .sidebar .sidebar-wrapper {
    overflow: auto !important;
  }

  /* INPUT FILE */
  .btn-file {
    position: relative;
    overflow: hidden;
  }
  .btn-file input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
  }

  label.file {
    color: #fff !important;
  }
  .popover-header {
    background: #f7f7f7 !important;
  }
  .p-calendar .input {
    height: 31px;
  }
  .DayPicker-Month {
    margin: 0 3em !important;
  }
  #tableSelectAsyncHeader {
    width: 150% !important;
  }

  #tableSelectAsyncBody {
    width: 150% !important;
    overflow-x: auto;
  }
  td.min-width-cpf {
    width: 110px;
  }
  td.min-width-horario {
    width: 80px;
  }
  td.code {
    width: 60px;
  }
  td.date {
    text-align: right;
    width: 50px;
  }

  /*PROFILE CIRCLE */
  .profile-circle-sm .head-circle-avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 18px;
    font-family: sans-serif;
    color: #fff;
    line-height: 37px;
    text-align: center;
    background: #fd9f15;
  }
  .profile-circle .head-circle-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 28px;
    font-family: sans-serif;
    color: #fff;
    line-height: 60px;
    text-align: center;
    background: #fd9f15;
  }

  /*CUSTOM COLO BTNS*/
  .page-item.active .page-link,
  .btn-primary {
    background-color: #08acce !important;
    border-color: #08acce !important;
  }

  dialog.dynamsoft-dwt-dialogProgress {
    top: 40% !important;
  }

  .modal-content {
    padding: 15px;
  }

  /*SLIDESHOW PDF*/
  .lg-next,
  .lg-prev {
    font-size: 50px !important;
  }
  .lg-close {
    font-size: 30px !important;
  }
  .lg-download,
  #lg-actual-size,
  #lg-zoom-in,
  #lg-zoom-out,
  .lg-fullscreen {
    display: none;
  }
  embed.plugin {
    border: 0px;
  }
  #frame-pdf {
  }

  .lg-thumb-item {
    background: #fff;
  }

  .messages-wrapper .message-out .message-body .message-content {
    background-color: #37bad8;
  }
  .messages-wrapper .message-out .message-body .message-content:before {
    border-left: 10px solid #37bad8;
  }

  @keyframes notificationAnimation {
    0% {
      margin-left: 0;
    }
    25% {
      margin-left: 7px;
    }
    50% {
      margin-left: 0;
    }
    75% {
      margin-left: -7px;
    }
    100% {
      margin-left: 0;
    }
  }

  .flaticon-alarm {
    color: #fff;
    font-weight: bold;
    animation: notificationAnimation 500ms;
  }

  .btn-chamados {
    background-color: #08acce;
    color: #ffffff !important;
    content: "";
    text-align: center;
    width: 120px;
    position: absolute;
    z-index: 1000;
    border-radius: 0px 10px 10px 0px;
    padding: 5px;
  }

  .modal-header button.close span {
    position: absolute;
    right: 12px;
    top: 7px;
    font-size: 30px !important;
  }

  .modal-header {
    button {
      font-size: 30px !important;
    }
  }

  .clicked {
    cursor: pointer;
  }

  .btn-custom-pg {
    padding: 12px;
    font-size: 18px;
    color: #9a9a9a !important;
  }

  .alert-primary {
    background-color: #37bad8;
  }
  .alert-success {
    background-color: greenyellow;
  }

  .notif-box {
    left: -120px !important;
  }

  button:focus {
    box-shadow: none;
  }

  /* STATUS CIRCLE */
  .status-onoff {
    width: 10px;
    height: 10px;
    background: #f3545d;
    bottom: 2px;
    position: absolute;
    z-index: 1000;
    right: 6px;
    border-radius: 50%;
  }

  span.status-online {
    background: #7cd67c;
  }

  span.status-ausente {
    background: orange;
  }

  span.status-ocupado {
    background: red;
  }
  span.status-offline {
    background: #cccccc;
  }

  .btn-success {
    background: #7cd67c !important;
  }
  .btn-success:hover {
    background: #7cd67c !important;
  }

  .text-white {
    color: #ffffff;
  }
  .text-black {
    color: #444444;
  }

  /** Mais Margin */
  .min-3 {
    width: 3%;
  }
  .min-4 {
    width: 4%;
  }
  .min-5 {
    width: 5%;
  }
  .min-7 {
    width: 7%;
  }
  .min-25 {
    width: 25%;
  }
  /** Mais Padding */
  .pl-7 {
    padding-left: 3rem;
  }

  /** FLEX - SHORTCUT */
  .justify-start {
    display: flex;
    justify-content: flex-start;
  }
  .justify-center {
    display: flex;
    justify-content: center;
  }
  .justify-end {
    display: flex;
    justify-content: flex-end;
  }
  .gap-1 {
    font-size: 1rem;
  }

  // Fontsize
  .fs-16 {
    font-size: 16px !important;
  }

  .border-fieldset {
    display: block;
    line-height: 1.5;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-clip: padding-box;
    margin-left: 10px !important;
    /* margin-top: 25px; */
    border-image: initial;
  }

  // Negavite - Margins & Paddings
  .n-mt-1 {
    margin-top: -0.8rem !important;
  }
  .n-mt-2 {
    margin-top: -2rem !important;
  }
`;
