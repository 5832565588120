import produce from 'immer';
import { setCliente } from '~/components/Services/Auth';

const INITIAL_STATE = {
  modulo: '',
  entidade: {
    codigo: null,
    nome: '',
    estado: '',
    municipio: '',
  },
};

export default function main(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@main/SET_ENTIDADE': {
        draft.entidade = action.payload.entidade;
        setCliente(action.payload.entidade.codigo);
        break;
      }

      case '@main/REMOVE_ENTIDADE': {
        draft.entidade = null;
        break;
      }

      case '@main/SET_MODULO': {
        draft.modulo = action.payload.modulo;
        break;
      }

      case '@main/UPDATE_ENTIDADE': {
        draft.entidade = action.payload.entidade;
        break;
      }

      default:
    }
  });
}
